<template>
	<main class="page-container" id="join-test-main">
    <div class="row">
      <button class="btn btn-md btn-primary col-3" @click="moveLgn">login-test</button>
      <button class="btn btn-md btn-gray col-3" @click="moveTrans">trans-test</button>
    </div>
	</main>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	name: 'JoinGeneralComplete',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('join', ['userNm']),
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {
    moveLgn() {
      this.$router.push({name: 'JoinTestLogin'})
    },
    moveTrans() {
      this.$router.push({name: 'JoinTestTrans'})
    },
  },
	destroyed() {},
};
</script>
